import * as i0 from '@angular/core';
import { inject, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Pipe } from '@angular/core';
import { tuiInjectIconResolver, TUI_ICON_START, TUI_ICON_END } from '@taiga-ui/core/tokens';
class TuiIcon {
  constructor() {
    this.resolver = tuiInjectIconResolver();
    this.icon = inject(TUI_ICON_START, {
      self: true,
      optional: true
    }) || inject(TUI_ICON_END, {
      self: true,
      optional: true
    }) || '';
    this.background = '';
  }
  static {
    this.ɵfac = function TuiIcon_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiIcon)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiIcon,
      selectors: [["tui-icon"]],
      hostVars: 4,
      hostBindings: function TuiIcon_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("--t-icon", "url(" + ctx.resolver(ctx.icon) + ")")("--t-icon-bg", ctx.background ? "url(" + ctx.resolver(ctx.background) + ")" : null);
        }
      },
      inputs: {
        icon: "icon",
        background: "background"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function TuiIcon_Template(rf, ctx) {},
      styles: ["tui-icon{position:relative;display:inline-block;width:1em;height:1em;font-size:1.5rem;flex-shrink:0;vertical-align:middle;box-sizing:border-box;-webkit-mask:var(--t-icon-bg) no-repeat center / contain;mask:var(--t-icon-bg) no-repeat center / contain}tui-icon:after,tui-icon[tuiIcons]:after{position:absolute;top:0;left:0;width:100%;height:100%;content:\"\";display:block;-webkit-mask:var(--t-icon) no-repeat center / contain;mask:var(--t-icon) no-repeat center / contain;background:currentColor}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiIcon, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-icon',
      template: '',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[style.--t-icon]': '"url(" + resolver(icon) + ")"',
        '[style.--t-icon-bg]': 'background ? "url(" + resolver(background) + ")" : null'
      },
      styles: ["tui-icon{position:relative;display:inline-block;width:1em;height:1em;font-size:1.5rem;flex-shrink:0;vertical-align:middle;box-sizing:border-box;-webkit-mask:var(--t-icon-bg) no-repeat center / contain;mask:var(--t-icon-bg) no-repeat center / contain}tui-icon:after,tui-icon[tuiIcons]:after{position:absolute;top:0;left:0;width:100%;height:100%;content:\"\";display:block;-webkit-mask:var(--t-icon) no-repeat center / contain;mask:var(--t-icon) no-repeat center / contain;background:currentColor}\n"]
    }]
  }], null, {
    icon: [{
      type: Input
    }],
    background: [{
      type: Input
    }]
  });
})();
class TuiIconPipe {
  constructor() {
    this.transform = tuiInjectIconResolver();
  }
  static {
    this.ɵfac = function TuiIconPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiIconPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiIcon",
      type: TuiIconPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiIconPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiIcon'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiIcon, TuiIconPipe };
