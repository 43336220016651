import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, inject, Directive, Input } from '@angular/core';
import { tuiWithStyles } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiInjectIconResolver, TUI_ICON_START, TUI_ICON_END } from '@taiga-ui/core/tokens';
class TuiIconsStyles {
  static {
    this.ɵfac = function TuiIconsStyles_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiIconsStyles)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiIconsStyles,
      selectors: [["ng-component"]],
      hostAttrs: [1, "tui-icons"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function TuiIconsStyles_Template(rf, ctx) {},
      styles: ["[tuiIcons]{--t-icon-start: none;--t-icon-end: none}[tuiIcons]:before,[tuiIcons]:after{content:\"\";display:var(--t-icon-start);width:1em;height:1em;font-size:1.5rem;flex-shrink:0;background:currentColor;-webkit-mask:var(--t-icon-start) no-repeat center / contain;mask:var(--t-icon-start) no-repeat center / contain}[tuiIcons]:after{display:var(--t-icon-end);-webkit-mask:var(--t-icon-end) no-repeat center / contain;mask:var(--t-icon-end) no-repeat center / contain}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiIconsStyles, [{
    type: Component,
    args: [{
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'tui-icons'
      },
      styles: ["[tuiIcons]{--t-icon-start: none;--t-icon-end: none}[tuiIcons]:before,[tuiIcons]:after{content:\"\";display:var(--t-icon-start);width:1em;height:1em;font-size:1.5rem;flex-shrink:0;background:currentColor;-webkit-mask:var(--t-icon-start) no-repeat center / contain;mask:var(--t-icon-start) no-repeat center / contain}[tuiIcons]:after{display:var(--t-icon-end);-webkit-mask:var(--t-icon-end) no-repeat center / contain;mask:var(--t-icon-end) no-repeat center / contain}\n"]
    }]
  }], null, null);
})();
class TuiIcons {
  constructor() {
    this.nothing = tuiWithStyles(TuiIconsStyles);
    this.resolver = tuiInjectIconResolver();
    this.iconStart = inject(TUI_ICON_START, {
      self: true,
      optional: true
    }) || '';
    this.iconEnd = inject(TUI_ICON_END, {
      self: true,
      optional: true
    }) || '';
  }
  static {
    this.ɵfac = function TuiIcons_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiIcons)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiIcons,
      hostAttrs: ["tuiIcons", ""],
      hostVars: 4,
      hostBindings: function TuiIcons_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("--t-icon-start", ctx.iconStart ? "url(" + ctx.resolver(ctx.iconStart) + ")" : null)("--t-icon-end", ctx.iconEnd ? "url(" + ctx.resolver(ctx.iconEnd) + ")" : null);
        }
      },
      inputs: {
        iconStart: "iconStart",
        iconEnd: "iconEnd"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiIcons, [{
    type: Directive,
    args: [{
      standalone: true,
      host: {
        tuiIcons: '',
        '[style.--t-icon-start]': 'iconStart ? "url(" + resolver(iconStart) + ")" : null',
        '[style.--t-icon-end]': 'iconEnd ? "url(" + resolver(iconEnd) + ")" : null'
      }
    }]
  }], null, {
    iconStart: [{
      type: Input
    }],
    iconEnd: [{
      type: Input
    }]
  });
})();
class TuiWithIcons {
  static {
    this.ɵfac = function TuiWithIcons_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiWithIcons)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiWithIcons,
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: TuiIcons,
        inputs: ["iconStart", "iconStart", "iconEnd", "iconEnd"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiWithIcons, [{
    type: Directive,
    args: [{
      standalone: true,
      hostDirectives: [{
        directive: TuiIcons,
        inputs: ['iconStart', 'iconEnd']
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiIcons, TuiWithIcons };
