import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Directive, Input } from '@angular/core';
import { tuiWithStyles } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiButtonOptionsProvider } from '@taiga-ui/core/components/button';
import { tuiAvatarOptionsProvider } from '@taiga-ui/kit/components/avatar';
import { tuiBadgeOptionsProvider } from '@taiga-ui/kit/components/badge';
class TuiHeaderStyles {
  static {
    this.ɵfac = function TuiHeaderStyles_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHeaderStyles)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiHeaderStyles,
      selectors: [["ng-component"]],
      hostAttrs: [1, "tui-header"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function TuiHeaderStyles_Template(rf, ctx) {},
      styles: ["[tuiHeader]{position:relative;display:flex;align-items:flex-start;box-sizing:content-box;color:var(--tui-text-primary);text-align:left}[tuiHeader] [tuiTitle]{flex-grow:2}[tuiHeader] [tuiAccessories]{display:flex;align-items:center;gap:.75rem}[tuiHeader] [tuiAccessories]:before{content:\"\\200b\";visibility:hidden}[tuiHeader] [tuiSubtitle]{font:var(--tui-font-text-m);color:var(--tui-text-secondary)}[tuiHeader] [tuiCaption]{font:var(--tui-font-text-s);color:var(--tui-text-secondary);font-weight:500;text-transform:uppercase}[tuiHeader][data-size=xxl] [tuiTitle],[tuiHeader][data-size=xxl] [tuiAccessories]{font:var(--tui-font-heading-1)}[tuiHeader][data-size=xl] [tuiTitle],[tuiHeader][data-size=xl] [tuiAccessories]{font:var(--tui-font-heading-2)}[tuiHeader][data-size=l] [tuiTitle],[tuiHeader][data-size=l] [tuiAccessories]{font:var(--tui-font-heading-3)}[tuiHeader][data-size=m] [tuiTitle],[tuiHeader][data-size=m] [tuiAccessories]{font:var(--tui-font-heading-4)}[tuiHeader][data-size=s] [tuiTitle],[tuiHeader][data-size=s] [tuiAccessories]{font:var(--tui-font-heading-5)}[tuiHeader][data-size=xs] [tuiTitle],[tuiHeader][data-size=xs] [tuiAccessories]{font:var(--tui-font-heading-6)}[tuiHeader][data-size=xxs] [tuiTitle],[tuiHeader][data-size=xxs] [tuiAccessories]{font:var(--tui-font-text-xl)}[tuiHeader][data-size=xxl] [tuiSubtitle],[tuiHeader][data-size=xl] [tuiSubtitle],[tuiHeader][data-size=l] [tuiSubtitle]{font:var(--tui-font-text-l)}tui-root._mobile [tuiHeader] [tuiAccessories]{gap:1rem}tui-root._mobile [tuiHeader][data-size=xxs] [tuiTitle],tui-root._mobile [tuiHeader][data-size=xxs] [tuiAccessories]{font:var(--tui-font-heading-6)}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHeaderStyles, [{
    type: Component,
    args: [{
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'tui-header'
      },
      styles: ["[tuiHeader]{position:relative;display:flex;align-items:flex-start;box-sizing:content-box;color:var(--tui-text-primary);text-align:left}[tuiHeader] [tuiTitle]{flex-grow:2}[tuiHeader] [tuiAccessories]{display:flex;align-items:center;gap:.75rem}[tuiHeader] [tuiAccessories]:before{content:\"\\200b\";visibility:hidden}[tuiHeader] [tuiSubtitle]{font:var(--tui-font-text-m);color:var(--tui-text-secondary)}[tuiHeader] [tuiCaption]{font:var(--tui-font-text-s);color:var(--tui-text-secondary);font-weight:500;text-transform:uppercase}[tuiHeader][data-size=xxl] [tuiTitle],[tuiHeader][data-size=xxl] [tuiAccessories]{font:var(--tui-font-heading-1)}[tuiHeader][data-size=xl] [tuiTitle],[tuiHeader][data-size=xl] [tuiAccessories]{font:var(--tui-font-heading-2)}[tuiHeader][data-size=l] [tuiTitle],[tuiHeader][data-size=l] [tuiAccessories]{font:var(--tui-font-heading-3)}[tuiHeader][data-size=m] [tuiTitle],[tuiHeader][data-size=m] [tuiAccessories]{font:var(--tui-font-heading-4)}[tuiHeader][data-size=s] [tuiTitle],[tuiHeader][data-size=s] [tuiAccessories]{font:var(--tui-font-heading-5)}[tuiHeader][data-size=xs] [tuiTitle],[tuiHeader][data-size=xs] [tuiAccessories]{font:var(--tui-font-heading-6)}[tuiHeader][data-size=xxs] [tuiTitle],[tuiHeader][data-size=xxs] [tuiAccessories]{font:var(--tui-font-text-xl)}[tuiHeader][data-size=xxl] [tuiSubtitle],[tuiHeader][data-size=xl] [tuiSubtitle],[tuiHeader][data-size=l] [tuiSubtitle]{font:var(--tui-font-text-l)}tui-root._mobile [tuiHeader] [tuiAccessories]{gap:1rem}tui-root._mobile [tuiHeader][data-size=xxs] [tuiTitle],tui-root._mobile [tuiHeader][data-size=xxs] [tuiAccessories]{font:var(--tui-font-heading-6)}\n"]
    }]
  }], null, null);
})();
class TuiHeader {
  constructor() {
    this.nothing = tuiWithStyles(TuiHeaderStyles);
    this.size = 's';
  }
  static {
    this.ɵfac = function TuiHeader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHeader)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHeader,
      selectors: [["", "tuiHeader", ""]],
      hostAttrs: ["tuiHeader", ""],
      hostVars: 1,
      hostBindings: function TuiHeader_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size || "s");
        }
      },
      inputs: {
        size: [0, "tuiHeader", "size"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAvatarOptionsProvider({
        size: 's'
      }), tuiButtonOptionsProvider({
        size: 's'
      }), tuiBadgeOptionsProvider({
        size: 'm'
      })])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHeader, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHeader]',
      providers: [tuiAvatarOptionsProvider({
        size: 's'
      }), tuiButtonOptionsProvider({
        size: 's'
      }), tuiBadgeOptionsProvider({
        size: 'm'
      })],
      host: {
        tuiHeader: '',
        '[attr.data-size]': 'size || "s"'
      }
    }]
  }], null, {
    size: [{
      type: Input,
      args: ['tuiHeader']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiHeader };
